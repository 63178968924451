import $ from 'jquery'
import * as project from './functions'
import './polyfill'
//import superfish from "superfish"

window.onload = () => {

  console.log("loaded")

  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  
  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }  
  
  //$('#menu-main-menu').superfish();


  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        break
      default:
        $('#loader').fadeOut(500)
    }
  })
}
